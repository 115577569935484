import React from "react";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { Twitter as TwitterIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { COLOR_SECONDARY_DARK, COLOR_SECONDARY_MEDIUM } from "../config/colors";
import { ReactComponent as DiscordLogo } from "../assets/icons/DISCORD_ICON.svg";
import OpenSeaLogo from "../assets/icons/open_sea.png";
import NFTradeLogo from "../assets/icons/NFTRADE.png";

const useStyles = makeStyles(() => ({
  mmButton: {
    background: `${COLOR_SECONDARY_MEDIUM} !important`,
    "&:hover": {
      background: `${COLOR_SECONDARY_DARK} !important`,
    },
  },
  mmIcon: {
    width: 30,
  },
  mmAccount: {
    background: `${COLOR_SECONDARY_MEDIUM} !important`,
    color: "#fff !important",
    fontWeight: 700,
    marginRight: "1em",
    "&:hover": {
      background: `${COLOR_SECONDARY_DARK} !important`,
    },
  },
  snWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  snButtons: {
    color: "#fff !important",
  },
  customIconSvg: {
    width: 30,
    height: 30,
    color: "#fff",
  },
  newCustomIconSvg: {
    height: 14,
    width: 100,
    filter: "grayscale(1) invert(1)",
  }
}));

interface INavbarProps {}

const Navbar: React.FC<INavbarProps> = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="absolute" elevation={0} color="transparent">
        <Toolbar variant="dense">
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "#fff", fontWeight: "bold" }}
          >
            CHIGÜIRELAND
          </Typography>
          <Box className={classes.snWrapper}>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{ color: "#fff" }}
            >
              Whitepaper
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem>
                <Link
                  href="https://chiguireland.s3.amazonaws.com/ChiguireLand+Whitepaper+Spanish.pdf"
                  target="_blank"
                  rel="noreferrer"
                  underline="none"
                >
                  Español/Spanish
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  href="https://chiguireland.s3.amazonaws.com/ChiguireLand+Whitepaper+English.pdf"
                  target="_blank"
                  rel="noreferrer"
                  underline="none"
                >
                  Ingles/English
                </Link>
              </MenuItem>
            </Menu>

            <IconButton
              href="https://nftrade.com/collection/chiguireland"
              className={classes.snButtons}
            >
              <img src={NFTradeLogo} alt="" className={classes.newCustomIconSvg} />
            </IconButton>
            <IconButton
              href="https://opensea.io/collection/chiguireland"
              className={classes.snButtons}
            >
              <img src={OpenSeaLogo} alt="" className={classes.customIconSvg} />
            </IconButton>
            <IconButton
              href="https://twitter.com/chiguireland"
              className={classes.snButtons}
              size="medium"
            >
              <TwitterIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              href="https://discord.gg/vKvy46zzJ8"
              className={classes.snButtons}
            >
              <DiscordLogo className={classes.customIconSvg} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
