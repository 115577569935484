import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import Home from "./modules/home/Home";
import theme from "./shared/config/theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Home />
    </ThemeProvider>
  );
};

export default App;
