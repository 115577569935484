import React from "react";
import Navbar from "../../shared/layout/Navbar";
import HeroBanner from "./components/HeroBanner";

interface IHomeProps {}

const Home: React.FC<IHomeProps> = () => {
  return (
    <>
      <Navbar />
      <HeroBanner />
    </>
  );
};

export default Home;
