import React from "react";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import banner from "../../../shared/assets/banner_1920x1080.png";
import logo from "../../../shared/assets/logo_alone_1.png";
import soldOut from "../../../shared/assets/img/sold-out.png";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "calc(100vh - 96px)",
    background: "#000 !important",
    backgroundImage: `url(${banner}) !important`,
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "center !important",
    display: "grid",
    placeItems: "center",
  },
  logoWrap: {
    width: "50vw",
    height: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  img: {
    width: "80%",
    [theme.breakpoints.down("md")]: {
      minWidth: 200,
      maxWidth: 300,
    },
  },
  soldout: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "contain",
    transform: "rotate(-20deg)",
    filter: "drop-shadow(2px 4px 6px black)",
  },
}));

const HeroBanner: React.FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Box className={classes.logoWrap}>
        <img src={logo} alt="" className={classes.img} />
        <img src={soldOut} alt="" className={classes.soldout} />
      </Box>
    </section>
  );
};

export default HeroBanner;
