import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles/createTheme" {
  interface Theme {
    primary: {
      lighter: string;
      darker: string;
    };
  }
}

let theme = createTheme({
  // palette: {
  //   primary: {
  //     light: PRIMARY_COLOR_LIGHT,
  //     main: PRIMARY_COLOR_MAIN,
  //     dark: PRIMARY_COLOR_DARK,
  //   },
  //   secondary: {
  //     light: SECONDARY_COLOR_LIGHT,
  //     main: SECONDARY_COLOR_MAIN,
  //     dark: SECONDARY_COLOR_DARK,
  //   },
  //   info: {
  //     light: INFO_COLOR_LIGHT,
  //     main: INFO_COLOR_MAIN,
  //     dark: INFO_COLOR_DARK,
  //   },
  //   warning: {
  //     light: WARNING_COLOR_LIGHT,
  //     main: WARNING_COLOR_MAIN,
  //     dark: WARNING_COLOR_DARK,
  //   }
  // },
  typography: {
    fontFamily: ["Montserrat", "Poppins"].join(","),
    h1: {
      fontFamily: "Montserrat",
      fontWeight: 700,
    },
    h2: {
      fontFamily: "Montserrat",
      fontWeight: 700,
    },
    h3: {
      fontFamily: "Montserrat",
      fontWeight: 700,
    },
    h4: {
      fontFamily: "Montserrat",
      fontWeight: 700,
    },
    h5: {
      fontFamily: "Montserrat",
      fontWeight: 400,
    },
    h6: {
      fontFamily: "Montserrat",
      fontWeight: 500,
    },
    subtitle1: {
      fontFamily: "Montserrat",
      fontWeight: 400,
    },
    subtitle2: {
      fontFamily: "Montserrat",
      fontWeight: 500,
    },
    body1: {
      fontFamily: "Poppins",
      fontWeight: 400,
    },
    body2: {
      fontFamily: "Poppins",
      fontWeight: 400,
    },
    button: {
      fontFamily: "Poppins",
      fontWeight: 600,
    },
    caption: {
      fontFamily: "Poppins",
      fontWeight: 400,
    },
    overline: {
      fontFamily: "Poppins",
      fontWeight: 500,
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
